@tailwind base;
@tailwind components;
@tailwind utilities;


.font-main-title-home {
    font-family: 'Georgia', serif;

}

.font-subtitle-home {
    font-family: 'Georgia', sans-serif;
    font-style: italic;
}

.font-value-title-home {
    font-family: 'Georgia', serif;

}

.font-service-title-about {
    font-family: 'Georgia', serif;
    font-weight: lighter;
}

.font-value-tourism-dental {
    font-family: 'Georgia', serif;

}
